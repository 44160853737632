@import "../../../parameters.scss";

.owl-avatar {
  width: 100%;
  max-width: 150px;
}

.owl-avatar__pic {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 100%;
  background-color: $c_primary;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.75);

  img {
    position: absolute;
    width: 100%;
    border: 0;
  }

  .owl-avatar--noshadow & {
    box-shadow: none;
  }
}

.owl-avatar__placeholder {
  display: flex;
  position: absolute;
  top: 10%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    width: 80% !important;
    height: 80%;
  }
}
