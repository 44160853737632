@import "../../parameters.scss";

:root {
  --base-grid: 8px;
  --colour-body-background: #ffffff;
  --colour-background: rgb(91, 163, 192);
  --colour-background-folded: rgb(73, 156, 189);
  --colour-background-stripes: rgba(194, 194, 194, 0.5);
  --colour-text: #1a1a1a;
}

.projects {
  padding-bottom: 50px;
}

.projects__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.project-blocks {
  margin: calc(var(--base-grid) * 2) auto calc(var(--base-grid) * 5);
  margin-top: 40px;
  display: grid;
  grid-row-gap: calc(var(--base-grid) * 8);
  grid-column-gap: calc(var(--base-grid) * 6);
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--base-grid) * 35), 1fr));
  justify-items: center;
}

.new-project {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -16px;
  border: 2px dashed var(--colour-background);
  cursor: pointer;

  h2 {
    font-size: calc(var(--base-grid) * 4);
    line-height: 1.125;
    font-weight: 700;
  }

  &:hover {
    background-image: repeating-linear-gradient(
      -24deg,
      transparent,
      transparent 4px,
      var(--colour-background-stripes) 0,
      var(--colour-background-stripes) 5px
    );
  }
}

.project-block {
  cursor: pointer;
  display: block;
  position: relative;
  perspective: 1000px;
  animation-name: animateIn;
  animation-duration: 0.35s;
  animation-delay: calc(var(--animation-order) * 100ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.project-block:before {
  content: "";
  position: absolute;
  top: -16px;
  left: -16px;
  border: 2px dashed var(--colour-background);
  background-image: repeating-linear-gradient(
    -24deg,
    transparent,
    transparent 4px,
    var(--colour-background-stripes) 0,
    var(--colour-background-stripes) 5px
  );
  z-index: -1;
}

.project-block,
.new-project,
.project-block:before {
  width: calc(var(--base-grid) * 35);
  height: calc(var(--base-grid) * 35);
}

.project-block__link {
  background-color: var(--colour-body-background);
  border: 2px solid var(--colour-background);
  display: block;
  width: 100%;
  height: 100%;
}

.project-block__link:after {
  content: "";
  position: absolute;
  top: 50%;
  right: calc(var(--base-grid) * 3);
  width: calc(var(--base-grid) * 2);
  height: calc(var(--base-grid) * 2);
  margin-top: -8px;
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 25% 50%, 0 0);
  -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 25% 50%, 0 0);
  background-color: var(--colour-background);
  opacity: 0;
  transition: opacity 0.5s ease-in, transform 0.3s ease-in-out 0ms;
}

.project-block__content {
  background-color: var(--colour-background);
  color: var(--colour-text);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: calc(var(--base-grid) * 2);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--colour-background);
}

.project-block__content--lhs {
  clip-path: polygon(0 0, 51% 0, 51% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 51% 0, 51% 100%, 0 100%);
}

.project-block__content--rhs {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  transition: transform 0.5s ease-in-out, background-color 0.4s ease-in-out;
}

.project-block__title {
  font-size: calc(var(--base-grid) * 4);
  line-height: 1.125;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-break: anywhere;
}

.project-block__footer {
  margin-top: auto;
  font-size: calc(var(--base-grid) * 2);
  line-height: calc(var(--base-grid) * 2);
  display: flex;
  flex-direction: column;

  time {
    display: flex;
    align-items: center;

    svg {
      width: 25px;
      height: 25px;
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}

.project-block__participants {
  margin-bottom: 10px;
  .owl-avatar {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 5px;
    }
  }
}

.project-block__link:hover .project-block__content--rhs {
  background-color: var(--colour-background-folded);
  transform: rotateY(-50deg);
}

.project-block__link:hover:after {
  opacity: 1;
  transform: translateX(calc(var(--base-grid) * 1.5));
  transition: opacity 0.5s ease-in, transform 0.3s ease-in-out 0.25s;
}
