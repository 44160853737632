@import "../../../parameters.scss";

.storypage {
  .col-lg-8,
  .col-lg-4 {
    padding-bottom: 20px;
  }
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.ck-editor {
  @include tablet-only {
    overflow: hidden;
  }
}

//checklist
.story__checklist {
  .new-story__form {
    margin-top: 10px;
  }
}
.checklist__item {
  display: flex;
  width: 100%;
  margin: 3px 0 3px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: $c_background-grey;

  .form-check {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .form-check-label {
    height: 100%;
    width: 100%;
  }
}

.checklist__del {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.story__settings {
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: $c_background-grey;

  label {
    font-weight: bold;
  }
}

.story__settings-header {
  display: flex;
  justify-content: space-between;
}

.story__settings-range {
  display: flex;

  span {
    min-width: 30px;
    font-size: 12px;
    line-height: 20px;
  }
}

.story__settings-flag {
  display: flex;
  margin-bottom: 10px;

  span {
    margin-left: 5px;
    font-style: italic;
  }
}
