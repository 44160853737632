@import "../../parameters.scss";

.main-nav-bar {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, rgb(92, 37, 141), rgb(67, 137, 162));
}

.header__icon {
  margin-right: 10px;
  padding-right: 10px;
  cursor: pointer;

  img {
    height: 50px;
  }
}

.header__right {
  flex-shrink: 0;
  flex-direction: row !important;
}

.header__avatar {
  padding: 0;
}

.header__notification-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-right: 3px;
  z-index: 1;

  svg {
    z-index: -1;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 35px;
    height: 35px;
  }

  span {
    color: $c_primary;
    font-weight: bold;
  }
}

.nav-link--login {
  margin-left: 10px;
}

.header__notifications {
  width: 350px;
  max-height: 80vh;
  overflow: auto;
}

.header__notification {
  position: relative;
  white-space: unset;

  P {
    max-height: 80px;
    margin-bottom: 2px;
    overflow: hidden;
    font-size: 13px;
  }

  &:not(:first-child) {
    border-top: 1px solid grey;
  }
}

/*
.header__notification--unread::before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 10px;
  width: 3px;
  background-color: $c_primary;
}*/

.header__notification--subtitle {
  font-size: 12px;
  color: grey;
}
