@import "./parameters.scss";

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-body {
  display: flex;
  height: 100%;

  @include phone-only {
    flex-direction: column;
    overflow: auto;
  }
}

.app-body--reverse {
  @include phone-only {
    flex-direction: column-reverse;
  }
}

.app-body__content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  @include phone-only {
    //height: unset;
    margin-top: 70px;
    overflow: unset;

    .app-body--reverse & {
      margin-top: 0;
    }
  }
}

.app-body__content-float {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  overflow: auto;

  .app-body--reverse & {
    padding: 0;
  }

  @include phone-only {
    //position: relative;
  }
}

.app-body__loginbar {
  flex-shrink: 0;
  width: 360px;
  padding: 20px;
  overflow: auto;
  background-color: $c_background-grey;

  @include phone-only {
    width: 100%;
  }
}

.greybox {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  padding: 20px;
  border-radius: 10px;
  background: rgb(233, 236, 239);
}

.home {
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
