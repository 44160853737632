$theme-colors: (
  "primary": rgb(92, 37, 141),
);

@import "../node_modules/bootstrap/scss/bootstrap";

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

.react-confirm-alert-overlay {
  background: rgba(27, 27, 27, 0.9);
}

.logincard {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.logincard__content{
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  overflow: hidden;
}

.logincard__body {
  padding: 30px;
}

.owl-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.logincard__header {
  display: flex;
  align-items: center;
  
}

.logincard__tab {
  flex: 1;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.logincard__tab--inactive {
  background-color: #f1f1f1;
}