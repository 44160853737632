.owl-avatar-editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  /*
  label {
    @include header-font-important;
  }
*/
  button {
    margin-top: 10px;
  }
}

.owl-avatar-editor--hide {
  display: none;
}

.owl-avatar {
  margin: auto;
}

.owl-avatar-edit__pic {
  margin-bottom: 20px;

  .owl-avatar {
    cursor: pointer;
  }
}
