@import "../../../parameters.scss";

.project-search {
  position: absolute;
  z-index: 10;
  top: 66px;
  left: 320px;
  width: calc(100% - 320px);
  height: calc(100% - 66px);
  animation: fadein 0.2s;

  @include phone-only {
    left: 0;
    top: 50%;
    width: 100%;
    height: 50%;
    border-top: 2px solid grey;
  }
}

.project-search--active {
  opacity: 1;
}

.project-search__backdrop {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.473);
}

.project-search__inner {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: white;
  overflow: auto;

  @include phone-only {
    max-width: none;
  }

  .story {
    height: fit-content;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 10px;
    background: white;
  }
}

.project-search__close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }
}

.project-search__no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 100px);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
