@import "../../../parameters.scss";

.sidebar {
  position: relative;
  width: 100%;
  max-width: 330px;
  background-color: $c_background-grey;

  @include phone-only {
    position: absolute;
    max-width: unset;
    height: calc(100% - 70px);
    max-height: 70px;
    z-index: 9;
    overflow: hidden;
  }
}

.sidebar--open {
  max-height: unset;
  overflow: auto;
}

.sidebar__top-bar {
  display: flex;

  h2 {
    flex: 1;
    margin: 0;
  }

  @include phone-only {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 70px;
    margin-top: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    margin-left: -20px;
    padding: 15px 20px;
    background-color: $c_background-grey;
  }
}

.sidebar__spacer {
  @include phone-only {
    margin-top: 70px;
  }
}

.sidebar__toggle {
  display: none;
  align-items: center;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }

  @include phone-only {
    display: flex;
  }
}

.sidebar__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;

  @include phone-only {
    position: unset;
    height: unset;
    overflow: unset;
  }
}

.sidebar__item {
  display: flex;
  align-items: center;
  margin-left: -10px;
}

.sidebar__icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  color: $c_primary;

  .active & {
    color: white;
  }
}

.search-group {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  input {
    border: none;
  }
}

.search-group__icon {
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 5px;
  color: $c_primary;
  background-color: white;

  svg {
    width: 25px;
    height: 25px;
  }
}
