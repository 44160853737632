@import '../../../parameters.scss';

.new-story {
  margin: 10px 0;
}

.new-story__form {
  display: flex;
  align-items: center;
  max-width: $standard-max-width;

  .form-group {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0;
  }
}