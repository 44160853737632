.notepage__header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .form-group {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0;
  }

  h2 {
    flex: 1;
    margin-right: 10px;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}
