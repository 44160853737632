@import "../../../parameters.scss";

.dep-bar {
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
  background-color: white;
  border: 1px solid white;

  .story__body {
    flex-direction: column;
    min-height: 41px;
  }
}

.dep-bar--set {
  border: 1px solid #7c97b5;
}

.dep-bar__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dep__select {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
}
