@import "../../../parameters.scss";

.explainer {
  position: relative;
  width: 100%;
  padding: 30px 20px 20px 20px;
  background-color: $c_background-grey;
  border-radius: 20px;
}

.explainer__icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: $c_primary;

  svg {
    width: 50px;
    height: 50px;
  }
}
