@import "../../parameters.scss";
.chat {
  background-color: $c_background-grey;
  border-radius: 5px;
  margin-bottom: 100px;
}

.chat__messages {
  margin-bottom: 15px;
  padding: 10px 20px 20px;
  max-height: 600px;
  overflow: auto;
}

.chat__field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
}

.chat__options {
  position: relative;
  display: flex;
  margin-bottom: 2px;
}

.chat__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 5px;

  span {
    padding-bottom: 2px;
    font-size: 30px;
  }

  &:hover {
    background-color: $c_info;
    color: white;
  }
}

.chat-emoji {
  font-size: 25px;
}

.emojiWrapper {
  position: absolute !important;
  bottom: 10px;
  left: 0;
}

.chat-message {
  display: flex;
  margin-top: 10px;
  margin-right: 15px;
}

.chat-message--self {
  position: relative;
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 15px;

  .dropdown {
    position: absolute;
    top: -5px;
    right: -10px;
  }
}

.chat-deleted {
  color: grey;
  font-style: italic;
}

.chat-message__inner {
  display: flex;
  width: 100%;
  max-width: 500px;

  .owl-avatar {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}

.chat-message__cloud {
  flex: 1;
  padding: 10px;
  background-color: white;
  border-radius: 10px;

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
  }

  .chat-message--self & {
    background-color: #daecf1;
  }
}

.chat-message__meta {
  color: grey;
  font-size: 12px;
}

.chat-user {
  padding: 0 5px 2px;
  margin: 0 3px;
  color: white;
  background-color: #17a2b8;
  border-radius: 5px;
}

.chat__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.chat-item {
  flex: 1;
}

.chat-item__control {
  margin-top: 10px;
  margin-bottom: 10px;
}
.chat-item__input {
  background-color: #fff;
  transition: 0.3s ease-in-out;
  padding: 9px;
  font-size: 14px;
  line-height: 1.52857143;
  color: #333;
  border: 1px solid #dedede;
  &:focus,
  &:active {
    outline: none;
    border: 1px solid #3fb9de;
  }
  &::placeholder {
    color: #a4b0be;
    font-size: 14px;
  }
}

.chat-item__highlighter {
  padding: 0 10px;
}
.chat-item__suggestions__list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}
.chat-item__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.chat-item__suggestions__item--focused {
  background-color: #daf4fa;
}
