@import "../../../../parameters.scss";

.chips {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: $c_background-grey;
}

.chips__chips {
  width: 100%;
}

.chips__chip {
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $c_info;
  color: white;

  &:hover {
    background-color: $c_primary;
  }
}

.chips__select {
  width: 250px;
  font-size: 12px;

  .dark-theme & {
    color: white;
    > div {
      background-color: #151515;
    }

    div {
      color: white;
    }
  }
}
