.project-settings__header {
  display: flex;
  justify-content: space-between;
}

.project-settings__team {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 200px;
  max-height: 500px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;
  background: rgb(233, 236, 239);
  overflow: auto;
}

.teammember {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;

  .owl-avatar {
    width: 50px;
    height: 50px;
  }

  p {
    margin-bottom: 0;
  }
}

.teammember__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.teammember__name {
  font-weight: bold;
}

//chip

.chip-matthews {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: white;

  p {
    margin-bottom: 0;
  }
}

.chip-matthews__right {
  display: flex;
  align-items: center;
}

.chip-matthews__color {
  width: 35px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
}

.chip-matthews__picker {
  z-index: 2;
  position: absolute;
  right: 9px;
  top: 35px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}
