@import "../../parameters.scss";

.overview {
  h2 {
    margin-top: 20px;
  }
}

.overview__columns {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;

  @include tablet-only {
    display: flex;
    flex-direction: column-reverse;
  }
}

.overview__column {
  position: relative;
}
.overview__column__sticky {
  position: sticky;
  top: 0;
}

.owl__columns {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  @include tablet-only {
    grid-template-columns: 1fr;
  }
}

.itemlist {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $standard-max-width;
  min-height: 200px;

  > div {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
  }
}

.itemlist__item {
  user-select: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}

//side bar
.sidebar {
  .story {
    height: fit-content;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 10px;
    background: white;
  }

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//demotion
.demoteArrow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 20px 5px 0;
  max-width: $standard-max-width;
}

.categorize-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $standard-max-width;
}

.categorize-header__options {
  display: flex;
  align-items: center;
}