.pro {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  table {
    text-align: left;
  }

  .loader {
    height: 70px;
    min-height: 50px;
  }
}
