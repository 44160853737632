@import "../../../parameters.scss";

.range-step-bar {
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
  background-color: #7c97b5;
  color: white;

  .story__body {
    align-items: center;
    min-height: 41px;

    @include small-desktop-only {
      flex-direction: column;
      p {
        width: 100%;
      }

      .range-step-bar__right {
        width: 100%;
      }
    }
  }

  span {
    text-align: right;
    min-width: 35px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 20px;
  }
}

.range-step-bar--set {
  background-color: white;
  color: black;
}

.range-step-bar__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.range-step-bar__input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;

  .form-group {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
}

.range-step-bar__splitting {
  margin-top: 10px;
}

.range-step-bar__newstory {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;

  &:before {
    content: "";
    position: absolute;
    top: -30px;
    left: 6px;
    width: 12px;
    height: 50px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
  }

  &:first-child::before {
    top: -5px;
    height: 24px;
  }
}

.range-step-bar__splitting-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.range-step-bar__splitting-icons {
  display: flex;
  margin-bottom: 10px;

  button {
    margin-left: 5px;
  }
}

.range-step-bar__splitting-button {
  padding: 5px;
  cursor: pointer;
}
