// colors
$c_background-grey: #f2f4f7;

$c_primary: rgb(92, 37, 141);
$c_info: #17a2b8;

// sizes
$standard-max-width: 800px;

// responsive mixins
@mixin phone-only {
  @media (max-width: 699px) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin small-desktop-only {
  @media (max-width: 1099px) {
    @content;
  }
}

@mixin desktop-only {
  @media (max-width: 1399px) {
    @content;
  }
}
